import React from "react"
import Layout from "../components/layout"
import { Message } from "../components/message"
import SEO from "../components/seo"
import styles from "../style-utils/404.module.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className={styles.container}>
      <Message type="warning">
        <div>
          <h1>404 Error</h1>
          <p>
            Sorry the page you were looking for could not be found. Please try
            going back to the <a href="/">home page</a>.
          </p>
        </div>
      </Message>
    </div>
  </Layout>
)

export default NotFoundPage
