import React from 'react';
import { IoIosCloseCircle, IoMdCheckmarkCircle, IoMdInformationCircle, IoIosWarning } from 'react-icons/io';
import styles from './message.module.scss';

export const Message = (props) => {
  const type = styles[`${props.type}Message`] || '';
  return (
    <div className={`${styles.message} ${type}`}>
      <i className={styles.icon}>
        { props.type === 'warning' && <IoIosWarning/> }
        { props.type === 'error' && <IoIosCloseCircle/> }
        { props.type === 'success' && <IoMdCheckmarkCircle/> }
        { props.type === 'info' && <IoMdInformationCircle/> }
      </i>
      {props.children}
    </div>
  );
}